"use client";
import Link from "next/link";

import { Button } from "@/components/ui/button";
import { useSignInModal } from "../layout/sign-in-modal";

export function CtaSection() {
    const { SignInModal, setShowSignInModal } = useSignInModal(true, '/dashboard/billing');
    return (
        <>
            <SignInModal />
            <section className="container relative flex flex-col items-center gap-6 py-20 sm:gap-10">
                <h2 className="max-w-xl text-center font-heading text-3xl font-semibold sm:text-4xl sm:leading-tight">
                    Web Scraping Done Right
                </h2>
                <p className="max-w-xl text-center text-lg text-muted-foreground">
                    Get the data you need without the hassle of managing infrastructure or the cost of paying
                    for 90%+ margins from other API providers.
                </p>
                <Button
                    size="lg"
                    asChild
                    variant="default"
                    className="h-12 cursor-pointer border-border text-base sm:h-14 sm:px-10"
                    onClick={() => setShowSignInModal(true)}
                >
                    <Link href="#">Get Scraping Today</Link>
                </Button>
            </section>
        </>
    );
}
