"use client";
import { ArrowRight } from "lucide-react";
import Link from "next/link";

import { Button } from "@/components/ui/button";
import { useSignInModal } from "../layout/sign-in-modal";

export function Hero() {
    const { SignInModal, setShowSignInModal } = useSignInModal(true, '/dashboard/billing');
    return (
        <>
            <SignInModal />
            <section className="container flex flex-col items-center gap-8 pt-20 sm:gap-10">
                <div onClick={() => setShowSignInModal(true)} className="flex cursor-pointer items-center gap-1 rounded-full border bg-secondary px-4 py-1 hover:bg-secondary/60">
                    <span className="text-sm text-secondary-foreground">Introducing GetScraping.com</span>
                    <ArrowRight size={16} />
                </div>
                <h1 className="max-w-5xl text-center font-heading text-4xl font-semibold sm:text-5xl sm:leading-tight">
                    Tired of getting blocked while scraping the web?
                </h1>
                <p className="max-w-lg text-center text-lg text-muted-foreground sm:text-xl">
                    Our API handles proxies, javascript rendering, and more to ensure you can scrape the web without getting blocked.
                </p>
                <div className="grid grid-cols-2 gap-3">
                    <Button
                        size="lg"
                        asChild
                        variant="outline"
                        className="h-12 cursor-pointer border-border text-base sm:h-14 sm:px-10"
                    >
                        <Link href="#features">Learn More</Link>
                    </Button>
                    <Button
                        size="lg"
                        asChild
                        className="h-12 cursor-pointer border-border text-base sm:h-14 sm:px-10"
                        onClick={() => setShowSignInModal(true)}
                    >
                        <Link href="#">Try For Free</Link>
                    </Button>
                </div>
                <div className="relative sm:mt-8">
                    <div className="absolute inset-0 -z-10 bg-primary/10 [filter:blur(180px)]" />
                </div>
            </section>
        </>
    );
}
