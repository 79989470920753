import Modal from "./modal";
import { signIn } from "next-auth/react";
import {
    useState,
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
} from "react";
import Github from "@/components/icons/github";
import LoadingDots from "@/components/icons/loading-dots";

import Image from "next/image";

const SignInModal = ({
    showSignInModal,
    setShowSignInModal,
    register,
    callbackURL,
}: {
    showSignInModal: boolean;
    setShowSignInModal: Dispatch<SetStateAction<boolean>>;
    register: boolean;
    callbackURL: string;
}) => {
    const [signInClicked, setSignInClicked] = useState(false);
    // const [email, setEmail] = useState("");

    // const handleMagicLinkSignIn = (e: React.FormEvent) => {
    //     e.preventDefault();
    //     setSignInClicked(true);
    //     signIn("resend", { email });
    // };
    return (
        <Modal showModal={showSignInModal} setShowModal={setShowSignInModal}>
            <div className="w-full overflow-hidden shadow-xl md:max-w-md md:rounded-2xl md:border md:border-gray-200">
                <div className="flex flex-col items-center justify-center space-y-3 border-b border-gray-200 bg-white px-4 py-6 pt-8 text-center md:px-16">
                    <a href="https://getscraping.com">
                        <Image
                            src="/logo.svg"
                            alt="Logo"
                            className="size-10 rounded-full"
                            width={20}
                            height={20}
                        />
                    </a>
                    {register ? (
                        <h3 className="font-display text-2xl font-bold">Sign Up</h3>

                    ) : (
                        <h3 className="font-display text-2xl font-bold">Sign In</h3>
                    )}

                    {register ? (
                        <p className="text-sm text-gray-500">
                            Register for an account.
                        </p>
                    ) : (
                        <p className="text-sm text-gray-500">
                            Sign in to your account.
                        </p>
                    )
                    }
                </div>

                <div className="flex flex-col space-y-4 bg-gray-50 px-4 py-8 md:px-16">
                    <button
                        disabled={signInClicked}
                        className={`${signInClicked
                            ? "cursor-not-allowed border-gray-200 bg-gray-100"
                            : "border border-gray-200 bg-white text-black hover:bg-gray-50"
                            } flex h-10 w-full items-center justify-center space-x-3 rounded-md border text-sm shadow-sm transition-all duration-75 focus:outline-none`}
                        onClick={() => {
                            setSignInClicked(true);
                            signIn("github", { callbackUrl: callbackURL });
                        }}
                    >
                        {signInClicked ? (
                            <LoadingDots color="#808080" />
                        ) : register ? (
                            <>
                                <Github className="size-5" />
                                <p>Register with Github</p>
                            </>
                        ) : (
                            <>
                                <Github className="size-5" />
                                <p>Sign In with Github</p>
                            </>
                        )}
                    </button>
                    {/* <form onSubmit={handleMagicLinkSignIn} className="space-y-2">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                        <button
                            type="submit"
                            disabled={signInClicked}
                            className={`${signInClicked
                                ? "cursor-not-allowed border-gray-200 bg-gray-100"
                                : "border border-gray-200 bg-white text-black hover:bg-gray-50"
                                } flex h-10 w-full items-center justify-center space-x-3 rounded-md border text-sm shadow-sm transition-all duration-75 focus:outline-none`}
                        >
                            {signInClicked ? (
                                <LoadingDots color="#808080" />
                            ) : (
                                <>
                                    <Github className="h-5 w-5" />
                                    <p>Sign In with magic link</p>
                                </>
                            )}
                        </button>
                    </form> */}
                </div>
            </div>
        </Modal >
    );
};

export function useSignInModal(register: boolean, callbackUrl?: string) {
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [callbackURL, setCallbackURL] = useState(callbackUrl ?? '/dashboard/billing');
    const SignInModalCallback = useCallback(() => {
        return (
            <SignInModal
                showSignInModal={showSignInModal}
                setShowSignInModal={setShowSignInModal}
                register={register}
                callbackURL={callbackURL}
            />
        );
    }, [showSignInModal, setShowSignInModal]);

    return useMemo(
        () => ({ setShowSignInModal, SignInModal: SignInModalCallback, setCallbackURL }),
        [setShowSignInModal, SignInModalCallback, setCallbackURL],
    );
}