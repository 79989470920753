import(/* webpackMode: "eager" */ "/Users/bart/getscraping/getScraping/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["CtaSection"] */ "/Users/bart/getscraping/getScraping/packages/web/components/landing/cta-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/Users/bart/getscraping/getScraping/packages/web/components/landing/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/Users/bart/getscraping/getScraping/packages/web/components/landing/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Pricing"] */ "/Users/bart/getscraping/getScraping/packages/web/components/landing/pricing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/Users/bart/getscraping/getScraping/packages/web/components/ui/accordion.tsx");
