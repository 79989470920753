"use client";
import { Check } from "lucide-react";
import Link from "next/link";

import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useSignInModal } from "../layout/sign-in-modal";

export function Pricing() {
    const { SignInModal, setShowSignInModal, setCallbackURL } = useSignInModal(true, '/dashboard/billing');
    return (
        <>
            <SignInModal />
            <section id="pricing" className="container flex flex-col items-center gap-6 py-24 sm:gap-7">
                <div className="flex flex-col gap-3">
                    <span className="text-center font-bold uppercase text-primary">Pricing</span>
                    <h2 className="text-center font-heading text-3xl font-semibold sm:text-4xl">
                        Simple pricing
                    </h2>
                </div>
                <p className="max-w-lg text-center text-lg text-muted-foreground">
                    All plans include the first 1000 credits/month for free!
                </p>
                <div className="mt-7 grid w-full grid-cols-1 gap-7 lg:grid-cols-3">
                    <Card className="relative shadow-lg">
                        <CardContent className="flex flex-col items-start p-7">
                            <h4 className="font-heading text-2xl font-semibold text-foreground">Side Project</h4>
                            <p className="mt-2 text-muted-foreground">For small projects.</p>
                            <div className="mt-5">
                                <span className="font-heading text-5xl font-semibold">$49.99</span>
                                <span className="text-sm"> /month</span>
                            </div>
                            <ul className="mt-9 space-y-2">
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Includes 300,000 Credits</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Additional credits at $0.17/1000</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Unlimited Concurrency</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">
                                        Choose from datacenter, ISP, residential or mobile proxies.
                                    </span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Render JavaScript</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">
                                        Bypass all common anti-scraping measures
                                    </span>
                                </li>
                            </ul>
                            <Button onClick={() => { setCallbackURL(`/subscribe/monthly`); setShowSignInModal(true) }} size="lg" asChild className="mt-10 w-full">
                                <Link
                                    href="#">Get started</Link>
                            </Button>
                        </CardContent>
                    </Card>
                    <Card className="relative border-2 border-primary shadow-lg">
                        <CardContent className="flex flex-col items-start p-7">
                            <h4 className="font-heading text-2xl font-semibold text-foreground">Startup</h4>
                            <p className="mt-2 text-muted-foreground">For up and coming businesses.</p>
                            <div className="mt-5">
                                <span className="font-heading text-5xl font-semibold">$99.99</span>
                                <span className="text-sm"> /month</span>
                            </div>
                            <ul className="mt-9 space-y-2">
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Includes 1,500,000 Credits</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Additional credits at $0.07/1000</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Unlimited Concurrency</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">
                                        Choose from datacenter, ISP, residential or mobile proxies.
                                    </span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Render JavaScript</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">
                                        Bypass all common anti-scraping measures
                                    </span>
                                </li>
                            </ul>
                            <Button onClick={() => { setCallbackURL(`/subscribe/monthly`); setShowSignInModal(true) }} size="lg" asChild className="mt-10 w-full">
                                <Link
                                    href="#">Get started</Link>
                            </Button>
                        </CardContent>
                        <span className="absolute inset-x-0 -top-5 mx-auto w-32 rounded-full bg-primary px-3 py-2 text-center text-sm font-semibold text-primary-foreground shadow-md">
                            Most popular
                        </span>
                    </Card>
                    <Card className="relative shadow-lg">
                        <CardContent className="flex flex-col items-start p-7">
                            <h4 className="font-heading text-2xl font-semibold text-foreground">Business</h4>
                            <p className="mt-2 text-muted-foreground">For large web scraping projects.</p>
                            <div className="mt-5">
                                <span className="font-heading text-5xl font-semibold">$249.99</span>
                                <span className="text-sm"> /month</span>
                            </div>
                            <ul className="mt-9 space-y-2">
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Includes 6,000,000 Credits</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Additional credits at $0.056/1000</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Unlimited Concurrency</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">
                                        Choose from datacenter, ISP, residential or mobile proxies.
                                    </span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">Render JavaScript</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <Check size={20} className="text-primary" />
                                    <span className="text-sm text-muted-foreground">
                                        Bypass all common anti-scraping measures
                                    </span>
                                </li>
                            </ul>
                            <Button onClick={() => { setCallbackURL(`/subscribe/monthly`); setShowSignInModal(true) }} size="lg" asChild className="mt-10 w-full">
                                <Link
                                    href="#">Get started</Link>
                            </Button>
                        </CardContent>
                    </Card>
                </div>
            </section>
        </>
    );
}
